import { userInfoApi } from "@/services/signin";
import store from "@/store";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IUserState {
  userInfo: any;
}

@Module({ dynamic: true, store, name: "User" })
class User extends VuexModule implements IUserState {
  public userInfo = {};
  public canGetUser = true;

  @Mutation
  private SET_USERINFO(userInfo: any) {
    this.userInfo = userInfo;
    // document.title = (this.userInfo as any).tenant;
    localStorage.setItem("page_id", (this.userInfo as any).page_id);
  }

  @Mutation
  private SET_CANGETUSER(canGetUser: boolean) {
    this.canGetUser = canGetUser;
  }

  // 获取用户信息
  @Action({ rawError: true })
  public async getUserInfo() {
    const { data } = await userInfoApi();
    if (data) this.SET_USERINFO(data);
  }

  // 配置用户信息
  @Action({ rawError: true })
  public async setUserInfo(data: any) {
    if (data) this.SET_USERINFO(data);
  }

  // 获取用户信息
  @Action({ rawError: true })
  public async setCanGetUser(flag: boolean) {
    this.SET_CANGETUSER(flag);
  }
}
export const UserModule = getModule(User);
