// 码值转换
export const codeValueConversion = function (list: any, key: any) {
  let str = "";
  list.map((item: any) => {
    if (item.value === key) {
      str = item.label;
    }
  });
  return str;
};

// 地址跳转
export const addressJump = function (url: any /** 全路径 */) {
  const targetWin: any = window.open(url, "_self");
};

// 地址跳转
export const addressJumpToNewWindow = function (url: any /** 全路径 */) {
  const targetWin: any = window.open(url, "_blank");
};

// 文字超长截取 加...
export const computedStrLen = function (str: any, length: any) {
  length = window.innerWidth <= 1600 ? length : length * 1.7;
  let len = 0;
  let strNew = "";
  for (let i = 0; i < str.length; i++) {
    const ts = str[i];
    const c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
    if (len > length) {
      strNew = str.substr(0, i) + "...";
      break;
    } else {
      strNew = str;
    }
  }
  return strNew;
};
// 文字超长截取
export const computedStrLenInput = function (str: any, length: any) {
  let len = 0;
  let strNew = "";
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
    if (len > length) {
      strNew = str.substr(0, i);
      break;
    } else {
      strNew = str;
    }
  }
  return strNew;
};

// 计算字符串长度，不能大于length长度
export const computedStrLenOnly = function (str: any, length: number) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
  }
  if (len > length) {
    return false;
  }
  return true;
};
