import Vue from "vue";
import Vuex from "vuex";
import { IListState } from "./modules/list";
import { IMenuState } from "./modules/menu";
import { ISigninState } from "./modules/signin";
import { IUserState } from "./modules/user";
Vue.use(Vuex);

export interface IRootState {
  signin: ISigninState;
  user: IUserState;
  menu: IMenuState;
  list: IListState;
}
export default new Vuex.Store<IRootState>({});
