import request from "@/utils/request";
const baseUrl1 = "/api/iam/v1/a/odic";

// 获取登录用户信息（全局）
// GET {{BASE}}/api/iam/v1/u/user_info
export const userInfoApi = () =>
  request({
    url: baseUrl1 + "/user_info",
    method: "get",
  });

// 子应用登出
// GET http://account.dev.k8s.local/api/iam/v1/a/odic/logout
export const logout = (params: any) =>
  request({
    url: baseUrl1 + "/logout?pflow=" + params,
    method: "get",
  });

//   ### 子应用退出后回调  本地测试 id  后面加上  （+ '&iam=sso-iam-lxx'）  测试环境删除
// GET {{BASE}}http://account.dev.k8s.local/api/iam/v1/a/odic/login?pflow=
export const afterLogout = (id: any) =>
  request({
    method: "get",
    url: baseUrl1 + "/login?pflow=" + id, // + '&iam=sso-iam-lxx'
  });
