import VueByMain from "@/main";
import crudMethod from "@/services/archivetime"; //此处替换api
declare module "vue/types/vue" {
  interface Vue {
    $global: any;
  }
}
const TimerNames: any = {};
export default {
  setTimerByScheduleCode(scheduleCode: string, that: any = null) {
    TimerNames[scheduleCode] = setInterval(async () => {
      let res: any = await crudMethod.getListAPI({ scheduleStartDate: scheduleCode.split("~")[0], scheduleEndDate: scheduleCode.split("~")[1] });
      if (res.success && res.data?.length) {
        res = res.data[0];
      }
      if (res.dataStatus !== 1) {
        if (TimerNames[scheduleCode]) {
          window.clearInterval(TimerNames[scheduleCode]);
        }
        TimerNames[scheduleCode] = null;
        //打开
        if (res.dataStatus === 2) {
          VueByMain.$notification.success({
            message: "通知",
            description: `档期 ${scheduleCode} 的计算已完成，请前往查看`,
            onClick: () => {
              VueByMain.$router.push("/archivetime");
            },
          });
          if (that) {
            that.refreshData();
          }
        }
        //清除缓存中的数据
        let timerNameList: any = sessionStorage.getItem("CountTimerNameList");
        timerNameList = JSON.parse(timerNameList).filter((el: any) => {
          return el !== scheduleCode;
        });
        sessionStorage.setItem("CountTimerNameList", JSON.stringify(timerNameList));
      }
    }, 5000);
  },
  //去除对象下所有null undefined 空字符串的属性
  removeEmpty: function removeEmpty(obj: any) {
    Object.keys(obj).forEach((key: any) => {
      if (obj[key] && typeof obj[key] === "object") {
        removeEmpty(obj[key]);
      } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  },
};
