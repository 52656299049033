import { SigninModule } from "@/store/modules/signin";
import { UserModule } from "@/store/modules/user";
import nProgress from "nprogress";
import { Route } from "vue-router";
import router from "./router";

router.beforeEach(async (to: Route, _: Route, next: any) => {
  nProgress.start();
  if (UserModule.canGetUser) {
    await SigninModule.login();
    UserModule.setCanGetUser(false);
  }
  next();
});

router.afterEach((to: Route) => {
  nProgress.done();
});
