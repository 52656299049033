import { afterLogout, logout } from "@/services/signin";
import store from "@/store";
import { UserModule } from "@/store/modules/user";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface ISigninState {
  apiLoadingStatus: any;
}

@Module({ dynamic: true, store, name: "signin" })
class Signin extends VuexModule implements ISigninState {
  public apiLoadingStatus = false;

  @Mutation
  private SET_APILOADINGSTATUS(status: any) {
    this.apiLoadingStatus = status;
  }

  // 请求状态全局
  @Action
  public async loadingStatus(data: any) {
    this.SET_APILOADINGSTATUS(data);
  }

  // 子应用登录
  @Action
  public async login() {
    const res: any = await afterLogout("");
    if (res.success) {
      await UserModule.getUserInfo();
      UserModule.setCanGetUser(false);
    }
  }

  // 子应用 登出
  @Action
  public async LogOut() {
    const { data } = await logout("");
    await afterLogout(data.pflow);
  }
}

export const SigninModule = getModule(Signin);
