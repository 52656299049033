// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    disable: false,
    icon: null,
    idx: "1",
    meta: { hideIcon: true },
    name: "照片审核",
    openType: 1,
    path: "/fdm/demo/tree",
    path0: "/fdm/demo/tree",
    key: "tree",
  },
];
export default menus;
