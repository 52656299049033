import global from "@/global/global";
import store from "@/store";
import "@/styles/ant-variables.less";
import "@/styles/globel.less";
import Antd from "ant-design-vue";
import PlusUi from "plus-ui-bar";
import "plus-ui-bar/lib/style.css";
import Vue from "vue";
import App from "./App.vue";
import "./permission";
import router from "./router";

// 图片预览插件
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

Vue.use(Antd);
Vue.use(PlusUi);
Vue.use(VueViewer);
Vue.config.productionTip = false;
Vue.prototype.$global = global;
export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
