import { SigninModule } from "@/store/modules/signin";
import { UserModule } from "@/store/modules/user";
import { addressJump } from "@/utils/util";
import { message } from "ant-design-vue";
import axios from "axios";

const service = axios.create({
  baseURL: "",
  timeout: 60000,
});

// 请求拦截
service.interceptors.request.use(
  (config: any) => {
    if (!config.headers || !config.headers["X-CustomHeader"]) {
      SigninModule.loadingStatus(true);
    }
    return config;
  },
  (error: any) => {
    SigninModule.loadingStatus(false);
    Promise.reject(error);
  },
);

// 返回拦截
service.interceptors.response.use(
  (response: any) => {
    SigninModule.loadingStatus(false);
    const res = response.data;
    if (response.status === 200 && res.success) {
      // if (typeof res.data === 'string') {
      //   showMessage({ showType: 3, errorMessage: res.data || '操作成功！' })
      // }
      // res.data ? res.data : (res.data = 'noMessage')
      return Promise.resolve(res);
    } else {
      showMessage(res);
      return Promise.resolve({ data: res.data || "" });
    }
  },
  async (error: any) => {
    SigninModule.loadingStatus(false);
    if (error.message.indexOf("401") >= 0) {
      window.dispatchEvent(new CustomEvent("plusrequesterror", { detail: error }));
      UserModule.setCanGetUser(true);
      SigninModule.login();
      return Promise.reject(error);
    }
    showMessage({ showType: 2, errorMessage: error.message });
    return Promise.reject(error);
  },
);
function showMessage(res: any) {
  switch (res.showType) {
    case 1: //
      message.warning(res.errorMessage, 3);
      break;
    case 2: //
      message.error(res.errorMessage, 5);
      break;
    case 3: //
      message.success(res.errorMessage, 3);
      break;
    case 4: //
      message.info(res.errorMessage, 3);
      break;
    case 9: // 应用重定向
      addressJump(res.data.location);
      break;
    default:
      // console.log(res)
      break;
  }
}

export default service;
