// 应用路由
const routers = [
  {
    // acc表
    path: "/photo",
    name: "photo",
    meta: { hidden: false },
    component: () => import("@/views/photo/index.vue"),
  },
  {
    // acc表
    path: "/photo/upload",
    name: "photo_upload",
    meta: { hidden: false },
    component: () => import("@/views/photo/modules/Upload.vue"),
  },
];
export default routers;
