import request from "@/utils/request";
// 获取列表
const getListAPI = (params: any) =>
  request({
    url: "/api/sam-report/v1/schedule/queryList",
    method: "get",
    params,
  });

const addAPI = (data: any) =>
  request({
    url: "/api/sam-report/v1/schedule/create",
    method: "post",
    data,
  });

const countAPI = (params: any) =>
  request({
    url: "/api/sam-report/v1/schedule/dateCompute",
    method: "get",
    params,
  });

// 快速编辑
const editAPI = (data: any) =>
  request({
    url: "/api/sam-report/v1/schedule/update",
    method: "post",
    data,
  });

// 快速编辑
const listQuickEditAPI = (params: any, data: any) =>
  request({
    url: "/api/terminal/v1/123/123",
    method: "post",
    params,
    data,
  });

// 导出
const exportAPI = (params: any, data: any) =>
  request({
    url: "/api/terminal/v1/terminal/export",
    method: "get",
    params,
    data,
  });

export default {
  getListAPI,
  listQuickEditAPI,
  exportAPI,
  editAPI,
  addAPI,
  countAPI,
};
