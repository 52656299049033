
import { MenuModule } from "@/store/modules/menu";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefMenus from "@/router/modules/menus";
// import { Route } from "vue-router";
// import { UserModule } from "@/store/modules/user";
// import { computedStrLen } from "@/utils/util";

@Component({
  name: "Nav",
})
export default class extends Vue {
  // computedStrLen: any = computedStrLen;
  // get userInfo() {
  //   return UserModule.userInfo;
  // }

  @Prop({ default: 6 })
  flag: any;

  hide = false;
  breadcrumbs: any[] = [];

  findPath(arr: any, path: any) {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].path === path) {
        result.push(arr[i]);
        break;
      }
      if (arr[i].children && arr[i].children.length > 0) {
        const res: any = this.findPath(arr[i].children, path);
        if (res.length > 0) {
          result.push(arr[i]);
          result.push(...res);
          break;
        }
      }
    }
    return result;
  }
  get breadcrumbsNoMenu() {
    const res = this.findPath(DefMenus, this.$route.path);
    return res;
  }

  @Watch("flag", { immediate: true })
  onFlagChange(val: any) {
    this.hide = val !== 6;
  }

  // 获取当前导航
  @Watch("$store.state.Menu.menuItemKey") // { immediate: true }
  onMenuChange() {
    // console.log("nav-update", MenuModule.menuItemKey, MenuModule.menuListMap);
    const menuItem: any = MenuModule.menuListMap[MenuModule.menuItemKey];
    if (!menuItem?.idx) {
      return; // 未找到对应的菜单
    }
    // 使用/拆分menuItem.idx为数组
    const idxArr = menuItem.idx.split("/");
    let reslist: any = [];
    for (let i = 1; i < idxArr.length; i++) {
      // const idx = idxArr.slice(0, i + 1).join("/");
      // reslist = [...reslist, MenuModule.menuListMap[idx]];
      reslist.push(MenuModule.menuListMap[idxArr[i]]);
    }
    this.breadcrumbs = reslist;
  }

  // 点击面包屑
  clickBreadcrumbItem(item: any) {
    // 点击在目前继承iframe的架构体系下是没有意义的，所以暂时取消面包屑的点击事件
    // 如果openType===3 iframe嵌入替换store中的clickItem  其他走项目内的路由导航
    // if (item.openType === 3) {
    //   this.$router.push({ name: "ipath", query: { p: item.path0 } });
    // } else {
    //   this.$router.push(item.path);
    // }
  }
}
